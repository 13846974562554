/*
* Common Utilities:
*/

import * as verge from 'verge';

const EVT_FAKE_RESIZE = 'fake-resize';
const EVT_RESIZE = 'resize';

/**
 * @description Wraps the document.querySelector with cross-browser support
 * @param sel    Selector (HTMLELement)
 * @param el    Parent (HTMLELement) to look for the selector in or defaults to document.body
 * @returns {*} [] Dom Nodes based on selector or an empty []
 */
const selAll = (sel, el) => {
	if (!el) el = document.body;

	if (!el && el === null) {
		console.warn('Utils -> selAll', 'parent element was provided, but was equal to null. Returning empty array.', sel);
		return [];
	}

	el = el || document.body;
	return Array.from(el.querySelectorAll(sel));
};

// String utils
//
// resources:
//  -- mout, https://github.com/mout/mout/tree/master/src/string

/**
 * "Safer" String.toLowerCase()
 */
function lowerCase(str) {
	return str.toLowerCase();
}

/**
 * "Safer" String.toUpperCase()
 */
function upperCase(str) {
	return str.toUpperCase();
}

/**
 * Convert string to camelCase text.
 */
function camelCase(str) {
	str = replaceAccents(str);
	str = removeNonWord(str)
		.replace(/-/g, ' ') // convert all hyphens to spaces
		.replace(/\s[a-z]/g, upperCase) // convert first char of each word to UPPERCASE
		.replace(/\s+/g, '') // remove spaces
		.replace(/^[A-Z]/g, lowerCase); // convert first char to lowercase
	return str;
}

/**
 * Add space between camelCase text.
 */
function unCamelCase(str) {
	str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
	str = str.toLowerCase(); // add space between camelCase text
	return str;
}

/**
 * UPPERCASE first char of each word.
 */
function properCase(str) {
	return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
}

/**
 * camelCase + UPPERCASE first char
 */
function pascalCase(str) {
	return camelCase(str).replace(/^[a-z]/, upperCase);
}

function normalizeLineBreaks(str, lineEnd) {
	lineEnd = lineEnd || 'n';

	return str
		.replace(/rn/g, lineEnd) // DOS
		.replace(/r/g, lineEnd) // Mac
		.replace(/n/g, lineEnd); // Unix
}

/**
 * UPPERCASE first char of each sentence and lowercase other chars.
 */
function sentenceCase(str) {
	// Replace first char of each sentence (new line or after '.\s+') to
	// UPPERCASE
	return lowerCase(str).replace(/(^\w)|\.\s+(\w)/gm, upperCase);
}

/**
 * Convert to lower case, remove accents, remove non-word chars and
 * replace spaces with the specified delimeter.
 * Does not split camelCase text.
 */
function slugify(str, delimeter) {
	if (delimeter == null) {
		delimeter = '-';
	}

	str = replaceAccents(str);
	str = removeNonWord(str);
	str = trim(str) // should come after removeNonWord
		.replace(/ +/g, delimeter) // replace spaces with delimeter
		.toLowerCase();

	return str;
}

/**
 * Replaces spaces with hyphens, split camelCase text, remove non-word chars, remove accents and convert to lower case.
 */
function hyphenate(str) {
	str = unCamelCase(str);
	return slugify(str, '-');
}

/**
 * Replaces hyphens with spaces. (only hyphens between word chars)
 */
function unhyphenate(str) {
	return str.replace(/(\w)(-)(\w)/g, '$1 $3');
}

/**
 * Replaces spaces with underscores, split camelCase text, remove
 * non-word chars, remove accents and convert to lower case.
 */
function underscore(str) {
	str = unCamelCase(str);
	return slugify(str, '_');
}

/**
 * Remove non-word chars.
 */
function removeNonWord(str) {
	return str.replace(/[^0-9a-zA-Z\xC0-\xFF -]/g, '');
}


/**
 * Replaces all accented chars with regular ones
 */
function replaceAccents(str) {
	// verifies if the String has accents and replace them
	if (str.search(/[\xC0-\xFF]/g) > -1) {
		str = str
			.replace(/[\xC0-\xC5]/g, 'A')
			.replace(/[\xC6]/g, 'AE')
			.replace(/[\xC7]/g, 'C')
			.replace(/[\xC8-\xCB]/g, 'E')
			.replace(/[\xCC-\xCF]/g, 'I')
			.replace(/[\xD0]/g, 'D')
			.replace(/[\xD1]/g, 'N')
			.replace(/[\xD2-\xD6\xD8]/g, 'O')
			.replace(/[\xD9-\xDC]/g, 'U')
			.replace(/[\xDD]/g, 'Y')
			.replace(/[\xDE]/g, 'P')
			.replace(/[\xE0-\xE5]/g, 'a')
			.replace(/[\xE6]/g, 'ae')
			.replace(/[\xE7]/g, 'c')
			.replace(/[\xE8-\xEB]/g, 'e')
			.replace(/[\xEC-\xEF]/g, 'i')
			.replace(/[\xF1]/g, 'n')
			.replace(/[\xF2-\xF6\xF8]/g, 'o')
			.replace(/[\xF9-\xFC]/g, 'u')
			.replace(/[\xFE]/g, 'p')
			.replace(/[\xFD\xFF]/g, 'y');
	}

	return str;
}

/**
 * Searches for a given substring
 */
function contains(str, substring, fromIndex) {
	return str.indexOf(substring, fromIndex) !== -1;
}

/**
 * Truncate string at full words.
 */
function crop(str, maxChars, append) {
	return truncate(str, maxChars, append, true);
}

/**
 * Escape RegExp string chars.
 */
function escapeRegExp(str) {
	let ESCAPE_CHARS = /[\\.+*?^$[\](){}/'#]/g;
	return str.replace(ESCAPE_CHARS, '\\$&');
}

/**
 * Escapes a string for insertion into HTML.
 */
function escapeHtml(str) {
	str = str
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/'/g, '&#39;')
		.replace(/"/g, '&quot;');

	return str;
}

/**
 * Unescapes HTML special chars
 */
function unescapeHtml(str) {
	str = str
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&#39;/g, '\'')
		.replace(/&quot;/g, '"');
	return str;
}

/**
 * Escape string into unicode sequences
 */
function escapeUnicode(str, shouldEscapePrintable) {
	return str.replace(/[\s\S]/g, function(ch) {
		// skip printable ASCII chars if we should not escape them
		if (!shouldEscapePrintable && (/[\x20-\x7E]/).test(ch)) {
			return ch;
		}
		// we use "000" and slice(-4) for brevity, need to pad zeros,
		// unicode escape always have 4 chars after "\u"
		return '\\u' + ('000' + ch.charCodeAt(0).toString(16)).slice(-4);
	});
}

/**
 * Remove HTML tags from string.
 */
function stripHtmlTags(str) {
	return str.replace(/<[^>]*>/g, '');
}

/**
 * Remove non-printable ASCII chars
 */
function removeNonASCII(str) {
	// Matches non-printable ASCII chars -
	// http://en.wikipedia.org/wiki/ASCII#ASCII_printable_characters
	return str.replace(/[^\x20-\x7E]/g, '');
}

/**
 * String interpolation
 */
function interpolate(template, replacements, syntax) {
	let stache = /\{\{(\w+)\}\}/g; // mustache-like

	let replaceFn = function(match, prop) {
		return (prop in replacements) ? replacements[prop] : '';
	};

	return template.replace(syntax || stache, replaceFn);
}

/**
 * Pad string with `char` if its' length is smaller than `minLen`
 */
function rpad(str, minLen, ch) {
	ch = ch || ' ';
	return (str.length < minLen) ? str + repeat(ch, minLen - str.length) : str;
}

/**
 * Pad string with `char` if its' length is smaller than `minLen`
 */
function lpad(str, minLen, ch) {
	ch = ch || ' ';

	return ((str.length < minLen)
		? repeat(ch, minLen - str.length) + str : str);
}

/**
 * Repeat string n times
 */
function repeat(str, n) {
	return (new Array(n + 1)).join(str);
}

/**
 * Limit number of chars.
 */
function truncate(str, maxChars, append, onlyFullWords) {
	append = append || '...';
	maxChars = onlyFullWords ? maxChars + 1 : maxChars;

	str = trim(str);
	if (str.length <= maxChars) {
		return str;
	}
	str = str.substr(0, maxChars - append.length);
	// crop at last space or remove trailing whitespace
	str = onlyFullWords ? str.substr(0, str.lastIndexOf(' ')) : trim(str);
	return str + append;
}

let WHITE_SPACES = [
	' ', '\n', '\r', '\t', '\f', '\v', '\u00A0', '\u1680', '\u180E',
	'\u2000', '\u2001', '\u2002', '\u2003', '\u2004', '\u2005', '\u2006',
	'\u2007', '\u2008', '\u2009', '\u200A', '\u2028', '\u2029', '\u202F',
	'\u205F', '\u3000',
];

/**
 * Remove chars from beginning of string.
 */
function ltrim(str, chars) {
	chars = chars || WHITE_SPACES;

	let start = 0;
	let len = str.length;
	let charLen = chars.length;
	let found = true;
	let i;
	let c;

	while (found && start < len) {
		found = false;
		i = -1;
		c = str.charAt(start);

		while (++i < charLen) {
			if (c === chars[i]) {
				found = true;
				start++;
				break;
			}
		}
	}

	return (start >= len) ? '' : str.substr(start, len);
}

/**
 * Remove chars from end of string.
 */
function rtrim(str, chars) {
	chars = chars || WHITE_SPACES;

	let end = str.length - 1;
	let charLen = chars.length;
	let found = true;
	let i;
	let c;

	while (found && end >= 0) {
		found = false;
		i = -1;
		c = str.charAt(end);

		while (++i < charLen) {
			if (c === chars[i]) {
				found = true;
				end--;
				break;
			}
		}
	}

	return (end >= 0) ? str.substring(0, end + 1) : '';
}

/**
 * Remove white-spaces from beginning and end of string.
 */
function trim(str, chars) {
	chars = chars || WHITE_SPACES;
	return ltrim(rtrim(str, chars), chars);
}

/**
 * Capture all capital letters following a word boundary (in case the
 * input is in all caps)
 */
function abbreviate(str) {
	return str.match(/\b([A-Z])/g).join('');
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
const debounceEvent = (a, b = 250, c) => (...d) => clearTimeout(c, c = setTimeout(() => a(...d), b));

function updateUrlParameter(uri, key, value) {
	// remove the hash part before operating on the uri
	let i = uri.indexOf('#');
	let hash = i === -1 ? '' : uri.substr(i);
	uri = i === -1 ? uri : uri.substr(0, i);

	let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
	let separator = uri.indexOf('?') !== -1 ? '&' : '?';
	if (uri.match(re)) {
		uri = uri.replace(re, '$1' + key + '=' + encodeURIComponent(value) + '$2');
	} else {
		uri = uri + separator + key + '=' + encodeURIComponent(value);
	}
	return uri + hash; // finally append the hash as well
}

/**
 * Find Parent DOM Element with given className
 * @param el HTML Element
 * @param cls Parent Element Classname
 * @returns {HTMLElement}
 */
function findAncestor(el, cls) {
	while ((el = el.parentElement) && !el.classList.contains(cls)) ;
	return el;
}

/**
 * @description Utility for checking JS breakpoints, so they behave the same as CSS media query breakpoints.
 * @param breakName (string) - Possible values are "ms", "mp", "ml", "tp", "ds", "dm", "dl".
 * @param isMoreThan (boolean) - whether or not to use ">" logic.
 * @param andIsEqual (boolean) - whether or not to use ">=" or "<=" logic (combines with isMoreThan).
 * @return (boolean) - whether window width fell below or above the specified break point.
 */
function checkBP(breakName, isMoreThan = false, andIsEqual = false) {
	let winWidth = verge.viewportW();

	let logic = function(val) {
		if (isMoreThan) {
			if (andIsEqual) return winWidth >= val;
			return winWidth > val;
		}

		if (andIsEqual) return winWidth <= val;
		return winWidth < val;
	};

	switch (breakName) {
		case 'xs':
			return logic(320);
		case 'sm':
			return logic(576);
		case 'md':
			return logic(768);
		case 'lg':
			return logic(992);
		case 'xl':
			return logic(1200);
	}
}

/**
 * @description Sets a window resize handler that only triggers when the width changes. Which gets around the mobile issue of address bar appearance triggering the resize (height) handlers.
 *  Also allows you to use `fakeResize` to trigger the same functionality as a resize event, but without actually resizing the window.
 * @param {Funtion} cb - Callback function on resize, which passes the current width.
 * @param {boolean} [immediate] - if true, calls callback immediately, so you don't have to wait for a resize event.
 * @param {number} [dur] - A custom throttle duration. If `-1` is passed, no debounce will be used.
 * @param {boolean} [once] - If `true`, will remove the handler after calling once.
 */
function resizeWidthHandler(cb, immediate, dur = 300, once = false) {
	// we check previous width mainly so mobiles only trigger on width resize, even when the address bar appears and changes the vp height
	let prevWidth = verge.viewportW();
	let w;
	let handler;

	let fun = function() {
		w = verge.viewportW();
		if (prevWidth !== w) cb(w);
		prevWidth = w;

		// removes listeners
		if (once) {
			onEvt(window, EVT_RESIZE, handler, true);
			onEvt(window, EVT_FAKE_RESIZE, () => cb(), true);
		}
	};

	// using debounce instead of throttle because we're only interested in when the user stops resizing
	handler = dur === -1 ? fun : debounceEvent(fun, dur);

	onEvt(window, EVT_RESIZE, handler);
	onEvt(window, EVT_FAKE_RESIZE, () => cb());

	if (immediate) {
		setTimeout(() => {
			cb(prevWidth);
		}, 0);
	}
}

/**
 * @description Adds multiple (or single) event handlers on a given element.
 * @param {HTMLElement | Window | Document} [el] - Element to listen from.
 * @param {string} evts - Space separated string of event names.
 * @param {EventListenerOrEventListenerObject} fun - Function to use as an event handler.
 * @param {boolean} [remove] - If `true`, will remove listeners instead of adding them
 */
function onEvt(el, evts, fun, remove = false) {
	if (!remove) remove = false;

	(evts.split(' ')).forEach(function(evt) {
		if (remove) el.removeEventListener(evt, fun, false);
		else el.addEventListener(evt, fun, false);
	});
}

/**
 * Scrolls to an element
 * @param el - can either be an HTMLElement or a CSS selector
 */
function scrollToEl(el, dur = 500, offset = 0) {
	let originalEl = el;
	if (typeof el === 'string') el = document.querySelector(el);

	if (el) {
		$('html,body').animate({
			scrollTop: $(el).offset().top + offset,
		}, dur);
	} else {
		console.warn('scrollToEl', 'Element did not exist. Can\'t scroll there, sorry.', originalEl);
	}
}

/**
 * Adds a document click handler, optionally removing it again after first trigger
 * @param cb - callback on click. Will pass the original event in case custom functionality is needed.
 * @param targetsToIgnore - an array of HTMLElements which will not trigger the the document click.
 * @param classesToIgnore - an array of class names which will not trigger the the document click.
 * @param once - if true, will remove it after first trigger
 */
function docClick(cb, targetsToIgnore = null, classesToIgnore = null, once = true) {
	const fun = function(evt) {
		if (targetsToIgnore && targetsToIgnore.length || classesToIgnore && classesToIgnore.length) {
			let ignoreClick = false;

			if (targetsToIgnore) {
				targetsToIgnore.forEach(($el) => {
					let targ = evt.target;
					if (targ === $el) ignoreClick = true;
				});
			}

			if (classesToIgnore) {
				classesToIgnore.forEach((cls) => {
					let targ = evt.target;
					// console.log("targ", targ, cls, targ.classList.contains(cls), targ.closest("." + cls))
					if (targ.classList.contains(cls) || targ.closest('.' + cls)) ignoreClick = true;
				});
			}

			if (!ignoreClick) {
				if (once) document.body.removeEventListener('mousedown', fun);
				cb(evt);
			}
		} else {
			if (once) document.body.removeEventListener('mousedown', fun);
			cb(evt);
		}
	};

	// timeout just gives focus a chance to finish (on AutoSearch -> MegaCbMenu)
	setTimeout(() => {
		// had to use mousedown because click behaved weird on mouse up (on AutoSearch -> MegaCbMenu)
		document.body.addEventListener('mousedown', fun);
	}, 0);
}

/**
 * Ensures items in $items array are same height
 * @param $items
 * @param removeStyle
 * @param justClear
 */
function sameHeightItems($items, removeStyle = true, justClear = false) {
	let h = 0;

	$items.forEach(($el) => {
		if (removeStyle) $el.removeAttribute('style');
	});

	if (justClear) return;

	$items.forEach(($el) => {
		let thisH = getInnerHeight($el);
		if (thisH > h) h = thisH;
	});

	$items.forEach(($el) => {
		$el.style.height = h + 'px';
	});
}

function getInnerHeight($el) {
	let style = window.getComputedStyle($el, null);
	return parseInt(style.getPropertyValue('height').split('px')[0], 10);
}

/**
 * @description Takes a list of elements and ensures they are the same height on the same row. Styles are added within inline JS.
 * @param $list (element list) - list of elements to be used.
 * @param justClear
 */
function setFakeRowHeights($list, justClear = false) {
	let offTop;
	let prevTop;
	let itemPerRow = 0;
	let rowList = [];
	let multiList = [];


	$list.forEach((tile, i) => {
		offTop = $(tile).offset().top;

		if (itemPerRow) {
			// push to multilist if it has reached the the total of items per row
			if (i % itemPerRow == 0) {
				multiList.push(rowList);
				rowList = [];
			}

			rowList.push(tile);
		} else {
			// if offsetTop is different, means it's a new line
			if (i > 0 && prevTop !== offTop) {
				// find total items per row first based on offsetTop
				itemPerRow = rowList.length;
				multiList.push(rowList);
				rowList = [];
			}

			rowList.push(tile);
		}

		prevTop = offTop;

		// push the rest of items to multilist
		if (i == $list.length - 1) multiList.push(rowList);
	});

	multiList.forEach((row) => {
		if (row.length > 1) sameHeightItems(row, justClear);
		else sameHeightItems(row, true); // if only one tile is left, remove attribue 'style'
	});
}

function forEach(list, fn) {
	for (let i = list.length - 1; i >= 0; i--) {
		fn(list[i], i);
	}
}

export const utils = {
	selAll,
	upperCase,
	lowerCase,
	camelCase,
	unCamelCase,
	properCase,
	pascalCase,
	sentenceCase,
	slugify,
	hyphenate,
	unhyphenate,
	underscore,
	removeNonWord,
	normalizeLineBreaks,
	replaceAccents,
	contains,
	crop,
	escapeHtml,
	escapeRegExp,
	escapeUnicode,
	unescapeHtml,
	stripHtmlTags,
	removeNonASCII,
	interpolate,
	rpad,
	lpad,
	repeat,
	truncate,
	ltrim,
	rtrim,
	trim,
	abbreviate,
	debounceEvent,
	updateUrlParameter,
	findAncestor,
	checkBP,
	resizeWidthHandler,
	onEvt,
	scrollToEl,
	docClick,
	setFakeRowHeights,
	forEach,
};

