// Load polyfills if needed

const modernBrowser = 'fetch' in window && 'assign' in Object;
if (!modernBrowser) {
	const scriptElement = document.createElement('script');
	scriptElement.async = false;
	scriptElement.src = '../../assets/js/polyfills.bundle.js';
	document.head.appendChild(scriptElement);
	console.info('loaded polyfills');
}

require('es6-promise').polyfill();

import $ from 'jquery';

window.$ = window.jQuery = $;
import 'bootstrap';
import 'svgxuse';
import 'slick-carousel';
import 'perfect-scrollbar';
