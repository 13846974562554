import {utils} from 'global/js/utils';
import $ from 'jquery';

export class PopoverFootnotes {
	constructor() {
		this.$pg = $('.page-body');
		this.$popovers = this.$pg.find('[data-toggle=popover]');
		this.$footnotesAccordion = this.$pg.find('.js-footnotes-accordion .collapse');
		this.$footnotes = this.$pg.find('.js-footnotes-accordion ol');
		this.$footerLinks = this.$pg.find('[data-toggle=popover]');

		this.init();
	}

	init() {
		this.initPopOverLinks();
	}

	initPopOverLinks() {
		let $pg = this.$pg;
		let self = this;

		this.$popovers.on('click', (e) => {
			e.preventDefault();

			if (! this.isAccordionOpen(this.$footnotesAccordion[0])) {
				this.$footnotesAccordion.collapse('show');
			}

			this.scrollToFootnote(e);

			if (document.documentElement.clientWidth < 992) {
				this.$popovers.popover('hide');
			}
		});

		this.$footnotes.find('a').on('click', (e) => {
			e.preventDefault();

			let refElenc = this.getFootnotRef(e);
			let accordion = this.getParentAccordion(refElenc);

			if (! this.isAccordionOpen(accordion)) {
				this.expandAccordion(accordion);
			}

			this.scrollToFootnote(e);
			setTimeout(() => refElenc.focus(), 200);

			if (document.documentElement.clientWidth < 992) {
				this.$popovers.popover('disable');
			}
		});

		this.$popovers.popover({
			html: true,
			placement: 'top',
			trigger: 'hover focus',
			delay: {
				'show': 50,
				'hide': 200,
			},
			content: function() {
				self.onHover = $(this);
				const $this = $(this);
				const href = $this.attr('href');

				return $pg.find(`${href} .tooltipSource`).html();
			},
		}).on('shown.bs.popover', () => {
			let timeoutId = null;

			$('[role="tooltip"]').mouseenter(() => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}

				self.onHover.trigger('focus');
			});

			$('[role="tooltip"]').mouseleave(() => {
				timeoutId = setTimeout(() => {
								self.onHover.trigger('blur');
							}, 200);
			});
		});
	}

	isAccordionOpen(accordion) {
		return accordion.classList.contains('show');
	}

	scrollToFootnote(e) {
		let $el = this.getFootnotRef(e);

		utils.scrollToEl($el);
	}

	getFootnotRef(e) {
		return $(e.target.attributes.href.nodeValue)[0];
	}

	getParentAccordion(refElenc) {
		if (refElenc.parentNode === null ||
			typeof refElenc.parentNode.classList === 'undefined') {
			return refElenc;
		}

		if (refElenc.parentNode.classList.contains('collapse')) {
			return refElenc.parentNode;
		}

		return this.getParentAccordion(refElenc.parentNode);
	}

	expandAccordion(accordion) {
		let $el = $(accordion.parentNode);

		let body = $el.find('.sectionAccordion-body');
		let action = $el.find('.sectionAccordion-action');

		action[0].setAttribute('aria-expanded', true);
		$(body[0]).collapse('show');
	}
}
