/* eslint-disable no-trailing-spaces */
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import {utils} from './utils';
import {PopoverFootnotes} from './popoverFootnotes';
import {onWysiwyg} from './wysiwyg';

$(document).ready(function() {
	let pf = new PopoverFootnotes();
	onWysiwyg();
	scrollTop();
});


function scrollTop() {
	// Back to top button
	let $body = $('body');
	const clsHasScrolled = 'has-scrolled';

	$(window).on('scroll', () => {
		if (!window.innerWidth < 992) {
			if ($(window).scrollTop() > 100) {
				$body.addClass(clsHasScrolled);
			} else {
				$body.removeClass(clsHasScrolled);
			}
		}
	});

	// Handle back to top button click to scroll to top.
	$('.backToTop').on('click', (e) => {
		// console.log("back to top", e)
		$('html, body').animate({
			scrollTop: 0, // body will scroll to the beginning of the body. all we need is to scroll to the top of the window (0)
		}, 1250);
	});
}
