/*

	WYSIWYG

	- onWysiwyg
	- wrapTablePlugin

 */

function getWysiwygList() {
	return document.getElementsByClassName('wysiwyg');
}

export function onWysiwyg() {
	let wysList = getWysiwygList();

	for (let i = wysList.length - 1; i >= 0; i--) {
		let wysElem = wysList[i];

		wrapTablePlugin(wysElem);
	}
}

/*

	TABLE PLUGIN

	Insert the `table-responsive` wrapper for each table in `wysiwyg`.

 */

function getTableList(elem) {
	return elem.getElementsByTagName('table');
}

function hasRespWrap(elem) {
	return (elem &&
			elem.parentNode &&
			elem.parentNode.classList &&
			elem.parentNode.classList.contains('table-responsive'));
}

function addRespWrap(elem) {
	if (!elem) {
		return;
	}

	let wrapElem = createTableWrap();
	wrap(elem, wrapElem);
}


function createTableWrap() {
	let respTableWrap = document.createElement('div');
	respTableWrap.classList.add('table-responsive');

	return respTableWrap;
}

function wrap(elem, wrapper) {
	elem.parentNode.insertBefore(wrapper, elem);
	wrapper.appendChild(elem);
}

function wrapTablePlugin(wysElem) {
	let tableList = getTableList(wysElem);

	for (let i = tableList.length - 1; i >= 0; i--) {
		let table = tableList[i];

		if (hasRespWrap(table)) {
			continue;
		}

		addRespWrap(table);
	}
}
