// Function loadClass loads a Widget/Block/Page class by dynamically importing the js on demand if needed

import {utils} from './utils';
const {camelCase, pascalCase} = utils;

export function loadClass(className, classType='widget', $parentEl=document.body, isList = false) {
	let $cls;
	let nameCamelCase = camelCase(className);
	let namePascalCase = pascalCase(className);

	switch (classType) {
		case 'block':

			$cls = $parentEl.querySelector(`[data-blk="${namePascalCase}"]`);

			if (!$cls) {
				return Promise.reject(`${namePascalCase} ${pascalCase(classType)} not found in the parent element`);
			}
			return import(
				/* webpackChunkName:"block-" */
				/* webpackMode: "lazy" */
				`blocks/${nameCamelCase}/js/${nameCamelCase}.js`).then((wg) => {
				const WG = wg[namePascalCase];

				if (isList) {
					let $clsCol = $parentEl.querySelectorAll(`[data-blk="${namePascalCase}"]`);
					let instances = [];
					Array.from($clsCol).forEach(($cls, i) => {
						instances.push( new WG($cls));
					});
					if (instances.length === $clsCol.length) return instances;
				} else {
					return new WG($cls);
				}
			}).catch((error) => `${error} An error occurred while loading the ${namePascalCase} block`);

		case 'page':

			$cls = $parentEl.querySelector(`[data-pg="${namePascalCase}"]`);

			if (!$cls) {
				return Promise.reject(`${namePascalCase} ${pascalCase(classType)} not found in the parent element`);
			}

			return import(
				/* webpackChunkName:"page-" */
				/* webpackMode: "lazy" */
				`pages/${nameCamelCase}/js/${nameCamelCase}.js`).then((wg) => {
				const WG = wg[namePascalCase];

				if (isList) {
					let $clsCol = $parentEl.querySelectorAll(`[data-pg="${namePascalCase}"]`);
					let instances = [];
					Array.from($clsCol).forEach(($cls, i) => {
						instances.push( new WG($cls));
					});
					if (instances.length === $clsCol.length) return instances;
				} else {
					return new WG($cls);
				}
			}).catch((error) => `${error} An error occurred while loading the ${namePascalCase} page`);

		case 'widget':

			$cls = $parentEl.querySelector(`[data-wg="${namePascalCase}"]`);

			if (!$cls) {
				return Promise.reject(`${namePascalCase} ${pascalCase(classType)} not found in the parent element`);
			}

			return import(
				/* webpackChunkName: 'widget-' */
				/* webpackMode: "lazy" */
				`widgets/${nameCamelCase}/js/${nameCamelCase}.js`).then((wg) => {
				const WG = wg[namePascalCase];

				if (isList) {
					let $clsCol = $parentEl.querySelectorAll(`[data-wg="${namePascalCase}"]`);
					let instances = [];
					Array.from($clsCol).forEach(($cls, i) => {
						instances.push( new WG($cls));
					});
					if (instances.length === $clsCol.length) return instances;
				} else {
					return new WG($cls);
				}
			}).catch((error) => `${error} An error occurred while loading the ${namePascalCase} widget`);

		case 'widgetClass':

			$cls = $parentEl.getElementsByClassName(`${nameCamelCase}`);

			if (!$cls) {
				return Promise.reject(`${nameCamelCase} ${pascalCase(classType)} not found in the parent element`);
			}

			return import(
				/* webpackChunkName: 'widget-' */
				/* webpackMode: "lazy" */
				`widgets/${nameCamelCase}/js/${nameCamelCase}.js`).then((wg) => {
				const WG = wg[namePascalCase];

				if (isList) {
					let $clsCol = $parentEl.getElementsByClassName(`${nameCamelCase}`);
					let instances = [];
					Array.from($clsCol).forEach(($cls, i) => {
						instances.push( new WG($cls));
					});
					if (instances.length === $clsCol.length) return instances;
				} else {
					return new WG($cls);
				}
			}).catch((error) => `${error} An error occurred while loading the ${nameCamelCase} widget`);
	}
}
