/* eslint-disable no-trailing-spaces */
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import "./vendors";

// import utils from './utils';

// Widgets

import { loadClass } from "./loadClass";

let NS = "bc";
window[NS] = {};

$(document).ready(function () {
	// load widget scripts
	loadClass("advancedFilter", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the advancedFilter widget"
		);

	loadClass("pagination", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error + "An error occurred while loading the pagination widget"
		);

	loadClass("siteHeaderMegaMenu", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the siteHeaderMegaMenu widget"
		);

	loadClass("siteHeaderMegaMenuV2", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the siteHeaderMegaMenuV2 widget"
		);

	loadClass("siteHeaderTopBar", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the siteHeaderTopBar widget"
		);

	loadClass("siteHeaderTopBarV2", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the siteHeaderTopBarV2 widget"
		);

	loadClass("popupAlertWidget", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the popupAlertWidget widget"
		);

	loadClass("topStickyNav", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the topStickyNav widget"
		);

	// loadClass('siteHeaderMegaMenu', 'widget', document.body, true).then((wgAll) => {
	// 	wgAll.length > 0 ? wgAll.forEach((wg) => {
	// 		wg.init();
	// 	}) : '';
	// }).catch((error) => error + 'An error occurred while loading the siteHeaderMegaMenu widget'); upcomingEventsBlock

	loadClass("searchBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the searchBlock Block`
		);

	loadClass("simpleSearchBarBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the simpleSearchBarBlock`
		);

	loadClass("signatureFormBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Signature Form Block Block`
		);

	loadClass("addressValidationFormBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Address Validation Form Block Block`
		);

	loadClass("pageListingListViewBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Page Listing List View Block Block`
		);

	loadClass("upcomingEventsBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Upcoming Events Block`
		);

	loadClass("mapBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) => `${error} An error occurred while loading the Map Block`
		);

	loadClass("mapViewBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Map View Block`
		);

	loadClass("propertiesListBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Properties List Block`
		);

	loadClass("imageGalleryBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Image Gallery Block`
		);

	loadClass("relatedFacilitiesBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the related Facilities Block`
		);

	loadClass("newsTileBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the News Tile Block`
		);

	loadClass("relatedArticles", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Related Articles block`
		);
	// load block scripts
	loadClass("siteHeader", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the siteHeader block`
		);

	loadClass("accordionBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Accordion Block`
		);

	loadClass("locationSearchBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Location Search Block`
		);

	loadClass("historicalCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the historicalCarouselBlock`
		);

	loadClass("listingGalleryStaticBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the listingGalleryStaticBlock`
		);

	loadClass("imageCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the imageCarouselBlock`
		);

	loadClass("featureContentCarousel", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the FeatureContentCarousel`
		);

	loadClass("featureContentCarousel", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the FeatureContentCarousel`
		);

	loadClass("fiftyFiftySliderBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the FeatureContentCarousel`
		);
	loadClass("threeDTourBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the FeatureContentCarousel`
		);
	loadClass("overviewBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the OverviewBlock`
		);

	// loadClass('broadcastPage', 'page', document.body).then((pg) => {
	// 	pg ? pg.init() : '';
	// }).catch((error) => `${error} An error occurred while loading BroadcastPage`);

	loadClass("searchPage", "page", document.body)
		.then((pg) => {
			pg ? pg.init() : "";
		})
		.catch(
			(error) => `${error} An error occurred while loading SearchPage`
		);

	loadClass("retirementLivingHomePage", "page", document.body)
		.then((pg) => {
			pg ? pg.init() : "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading RetirementLivingHomePage`
		);

	loadClass("servicesSearchBarBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the ServicesSearchBarBlock`
		);

	loadClass("searchBarBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the ServicesSearchBarBlock`
		);

	loadClass("siteHeaderSearchBar", "widget", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the SiteHeaderSearchBar`
		);

	loadClass("siteHeaderSearchBarV2", "widget", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the SiteHeaderSearchBarV2`
		);

	loadClass("resourceFinderPage", "page", document.body)
		.then((pg) => {
			pg ? pg.init() : "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading ResourceFinderPage`
		);

	loadClass("resourceFinderBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Resource Finder Block`
		);

	loadClass("resourceListBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Resource List Block`
		);

	loadClass("searchLocationMapBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
					bl.init();
				})
				: '';
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Resource List Block`
		);
		
	loadClass('racCalculatorBlock', 'block', document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Resource List Block`
		);

	loadClass("heroBlockCarousel", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the Hero Block Carousel`
		);

	// portal
	loadClass("portalSiteHeader", "block", document.body, true)
		.then((blAll) => {
			console.log("portalsiteheader script", blAll);
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the portalSiteHeader block`
		);

	loadClass("contentCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the contentCarouselBlock block`
		);

	loadClass("servicesBlockRL", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the servicesBlockRL block`
		);

	loadClass("servicesBlockV2", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the servicesBlockV2 block`
		);

	loadClass("accommodationBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the accommodationBlock block`
		);

	loadClass("galleryCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the GalleryCarouselBlock block`
		);

	loadClass("portalProfileDropdown", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the portalProfileDropdown widget"
		);

	loadClass("portalNotificationWidget", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the portalNotificationWidget widget"
		);

	loadClass("propertyWidget", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the propertyWidget widget"
		);

	loadClass("propertyForSaleWidget", "widget")
		.then((wg) => {
			wg ? wg.init() : "";
		})
		.catch(
			(error) =>
				error +
				"An error occurred while loading the propertyForSaleWidget widget"
		);

	loadClass("assistanceServiceBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the assistanceServiceBlock`
		);

	loadClass("unitForSaleBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the unitForSaleBlock`
		);

	loadClass("imageHeaderBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the imageHeaderBlock`
		);

	loadClass("siteHeaderV2", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the siteHeaderV2`
		);

	loadClass("fundingOptionsContainerBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the fundingOptionsContainerBlock`
		);

	loadClass("purposeAndValueBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the purposeAndValueBlock`
		);

	loadClass("careerRolesCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the careerRolesCarouselBlock`
		);

	loadClass("careerRolesCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the careerRolesCarouselBlocks`
		);

	loadClass("careerRolesContainerBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the careerRolesContainerBlock`
		);

	loadClass("montageArticleCarouselBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the montageArticleCarouselBlock`
		);

	loadClass("montageArticleContent", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the montageArticleContent`
		);

	loadClass("montageIssueSearchBarBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the montageIssueSearchBarBlock`
		);

	loadClass("montageFeaturedArticleBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the montageFeaturedArticleBlock`
		);

	loadClass("careerHeaderBlock", "block", document.body, true)
		.then((blAll) => {
			blAll.length > 0
				? blAll.forEach((bl) => {
						bl.init();
				  })
				: "";
		})
		.catch(
			(error) =>
				`${error} An error occurred while loading the careerHeaderBlock`
		);

	// Theme switcher
	// themeSwitcher();
});

// Theme switcher(portable svelte component) enables user to switch theme in development and FE build surge.sh site
function themeSwitcher() {
	const baseUrl = document.location.host;
	if (
		baseUrl.indexOf("localhost:4") > -1 ||
		baseUrl.indexOf("surge.sh") > -1
	) {
		import(
			/* webpackChunkName:"theme-switcher" */
			/* webpackMode: "lazy" */
			`global/svelte/ThemeSwitcher.svelte`
		).then((ts) => {
			const ThemeSwitcher = ts.default;
			const TS = new ThemeSwitcher({
				target: document.body,
				data: {
					// Add all available themes here:
					themes: [
						{
							name: "default",
							filename: "styles.css",
						},
						{
							name: "mono",
							filename: "mono.css",
						},
					],
					// Timeout for loading theme overlay is displayed
					loadingDelay: 820,
				},
			});
		});
	}
}

import "./page";
